// Import dependencies
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Import components
import Layout from "../layouts/layout";
import SEO from "../components/seo/seo";
import CheckIcon from "../assets/icons/confirmation_icon.svg";
import LuckyFace from "../assets/images/lucky-draw.png";
import { navigate, Link } from "gatsby";
import ReferFriends from "../components/account/refer-friends";
import Logo from "../assets/icons/logo_green_login.svg";
import CategoryDrawer from "../components/cateogry-drawer/categoty-drawer";
import SearchBar from "../components/search-bar/searchBar";

// Import styles
import "./step.css";
import "./recycle.css";
import "./confirmation.css";

import { clearCart } from "../redux/actions/cartAction";
import { clearGenCart } from "../redux/actions/generalCartAction";
import { confirmationLoaded } from "../redux/actions/confirmationAction";
import { brandCountLoaded } from "../redux/actions/brandCountAction";
import { getFirebase } from "../api/firebase";
import { pushCartInformation } from "../redux/actions/checkoutAction";

const Confirmation = () => {
  const [showReferFriends, setShowReferFriends] = useState(false);
  const [orderId, setOrderId] = useState("");
  const [email, setEmail] = useState("");

  const dispatch = useDispatch();
  const confirmation = useSelector((state) => state.confirmation);

  useEffect(() => {
    // Get the orderId from the query parameter
    const searchParams = new URLSearchParams(window.location.search);
    const orderIdFromUrl = searchParams.get('orderId');
    setOrderId(orderIdFromUrl);

    if (
      !confirmation.confirmation.pushCartObj ||
      !orderIdFromUrl
    ) {
      // navigate("/");
    } else {
      try {
        console.log("confirmation", confirmation);

        dispatch(pushCartInformation(confirmation.confirmation.pushCartObj));
        dispatch(clearCart());
        dispatch(clearGenCart());

        setEmail(confirmation.confirmation.pushCartObj?.orderDoc?.email);

        dispatch(confirmationLoaded({ pushCartObj: {}, challengeObj: {} }));

        import("firebase/app").then((app) =>
          import("firebase/firestore").then((firestore) =>
            getFirebase(app).firestore().collection("brands").doc("count").get().then((doc) => {
              dispatch(brandCountLoaded(doc.data()));
            })
          )
        );
      } catch (err) {
        console.log(err);
      }
    }
  }, [confirmation, dispatch]);

  return (
    <Layout noFooter noHeader={!showReferFriends} stepPage={!showReferFriends} checkoutWhite={!showReferFriends} noWhatsapp>
      <SEO title="Confirmation" />

      {!showReferFriends ? (
        <>
          <div className="checkout_page_container">
            <div className="cart_checkout_container cart_mobile_hide">
              <div className="confirmation_thank_you">
                <CheckIcon />
                <h1>THANK YOU!</h1>
                <p>Order {orderId}</p>
              </div>
            </div>
            <div className="checkout_page_forms">
              <Link to="/" className="checkout_logo">
                <Logo />
              </Link>
              <h1 className="confirmation_title">Order confirmed</h1>
              <div className="confirmation_thank_you">
                <CheckIcon />
                <h1>THANK YOU!</h1>
                <p>Order {orderId}</p>
              </div>
              <div className="confimartion_cards_container">
                <div className="confimartion_card">
                  <div className="confirmation_card_content">
                    <h2>What’s next?</h2>
                    <p>
                      Check out the How it Works page for the details about what’s going to happen next.
                    </p>
                  </div>
                  <p className="recycle-link" onClick={() => navigate("/how-it-works")}>
                    <span>How it works</span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z"
                        fill="#1877F2"
                      />
                    </svg>
                  </p>
                </div>
                <div className="confimartion_card">
                  <div className="confirmation_card_content">
                    <h2 style={{ color: "#0AAE67" }}>Earn another $100</h2>
                    <p>
                      Share your unique referral code with friends. When they use it, they get $5 on
                      their first order, and you get $10 for up to 10 friends.
                    </p>
                  </div>
                  <p className="recycle-link" onClick={() => setShowReferFriends(true)}>
                    <span>Share your code</span>
                    <svg
                      width="16"
                      height="16"
                      viewBox="0 0 16 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 8.99997H12.17L7.29 13.88C6.9 14.27 6.9 14.91 7.29 15.3C7.68 15.69 8.31 15.69 8.7 15.3L15.29 8.70997C15.68 8.31997 15.68 7.68997 15.29 7.29997L8.71 0.699971C8.32 0.309971 7.69 0.309971 7.3 0.699971C6.91 1.08997 6.91 1.71997 7.3 2.10997L12.17 6.99997H1C0.45 6.99997 0 7.44997 0 7.99997C0 8.54997 0.45 8.99997 1 8.99997Z"
                        fill="#1877F2"
                      />
                    </svg>
                  </p>
                </div>
                <div
                  className="confimartion_card confimartion_card_full_text"
                  style={{ backgroundImage: `url(${LuckyFace})`, paddingRight: 170 }}
                >
                  <div className="confirmation_card_content">
                    <h2 style={{ color: "#E44739", textShadow: "0px 1px 0 #FCCB64, 0px -1px 0 #FCCB64, -1px 0 0 #FCCB64, 1px 0 0 #FCCB64" }}>
                      Lucky Draw!
                    </h2>
                    <p>
                      You’re auto-enrolled into our Lucky Draw each time you recycle with us. You now
                      stand a chance to win +$8 on your payout!
                    </p>
                    <p>Won by 1 in 20 orders</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
          <CategoryDrawer />
          <SearchBar />
          <div className="header_section_return">
            <div onClick={() => setShowReferFriends(false)}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M15.0005 7.0007H3.83047L8.71047 2.1207C9.10047 1.7307 9.10047 1.0907 8.71047 0.700703C8.32047 0.310703 7.69047 0.310703 7.30047 0.700703L0.710469 7.2907C0.320469 7.6807 0.320469 8.3107 0.710469 8.7007L7.30047 15.2907C7.69047 15.6807 8.32047 15.6807 8.71047 15.2907C9.10047 14.9007 9.10047 14.2707 8.71047 13.8807L3.83047 9.0007H15.0005C15.5505 9.0007 16.0005 8.5507 16.0005 8.0007C16.0005 7.4507 15.5505 7.0007 15.0005 7.0007Z"
                  fill="black"
                />
              </svg>
              <span>Refer friends</span>
            </div>
          </div>
          <ReferFriends email={email} />
        </>
      )}
    </Layout>
  );
};

export default Confirmation;
